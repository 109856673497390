import api from '@/services/Api';

class Certificates {
  /**
   * Validate certificate code
   * @returns {AxiosPromise<any>}
   */
  static validateCode(code) {
    return api.get(`/public/certificates/validate?code=${code}`);
  }
}

export default Certificates;
