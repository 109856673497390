<template>
  <div class="container">
    <h3 class="font-weight-bold mb-4">Certificate</h3>

    <div class="row my-4 text-center" v-if="showError">
      <div class="col">
        <div class="mb-4">
          <i class="far fa-exclamation fa-4x text-primary"></i>
        </div>
        <p>
          The validity of the certificate cannot be attested either because the certification code is erroneous, or because the IFAC Affiliate has not made his/her profile public.
        </p>
      </div>
    </div>

    <ifac-loader v-if="isValidating">Validating Certificate</ifac-loader>

  </div>
</template>

<script>
import Certificates from '@/services/Api/Public/Certificates';
import { IfacLoader } from '@ifac/ui';

export default {
  components: { IfacLoader },
  data() {
    return {
      isValidating: false,
      showError: false,
    };
  },
  mounted() {
    this.validateCode();
  },
  methods: {
    validateCode() {
      this.isValidating = true;
      Certificates.validateCode(this.$route.query.code).then((data) => {
        this.isValidating = false;
        const { affiliateId } = data.data.data;
        this.$router.push(`/affiliates/${affiliateId}?section=roles`);
      }).catch(() => {
        this.isValidating = false;
        this.showError = true;
      });
    },
  },
};
</script>
